import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductContext from '../context/ProductContext'; 
import './RelatedProductsCarousel.css';

const RelatedProductsCarousel = ({ currentProduct }) => {
  const { products } = useContext(ProductContext);
  const navigate = useNavigate();

  const relatedProducts = products.filter(product => product.SubTypeCode === currentProduct.SubTypeCode && product.ProdReference !== currentProduct.ProdReference);

  if (relatedProducts.length === 0) return null;

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="related-products-carousel">
      <h3>Produtos Relacionados</h3>
      <div className="carousel-container">
        {relatedProducts.map((product, index) => (
          <div key={product.ProdReference} className="carousel-item">
            <img src={`https://www.spotgifts.com.br/fotos/produtos/${product.MainImage}`} alt={product.Name} className="carousel-thumb" />
            <p>{product.Name.substring(0, 18)}</p>
            <button onClick={() => handleProductClick(product.ProdReference)}>Acessar</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedProductsCarousel;

// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-logo">
          {/* <img src="/images/canetas-logo-caneta-combr.svg" alt="Logo" /> */}
          <img src="/images/canetas-logo-caneta-grande-branco.png" alt="Logo" />
          
        </div>
        <nav className="footer-nav">
          <span>Links</span>
          <ul>
            <li><a href="/">Início</a></li>
            <li><a href="/catalog">Todos os produtos</a></li>      
            <li><a href="#about">Nossa história</a></li>
            <li><a href="#services">Nossos serviços</a></li>
            <li><a href="#contact">Contato</a></li>

          </ul>
        </nav>
        <div className='footer-atendimento'>
          <span>Atendimento:</span>
          <span>(11) 99367-7390</span>
        </div>
        <div className="footer-signature">
          <p>Desenvolvido por Jonathan Ferreira</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Canetas. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;

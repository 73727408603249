import React from 'react';


const WhatsAppTemplate = ({ nome, email, telefone, descricao, cart, validateForm }) => {
  const generateWhatsAppMessage = () => {
    let message = `Nome: ${nome}\nEmail: ${email}\nTelefone: ${telefone}\nDescrição: ${descricao}\n\nProdutos:\n`;
    cart.forEach(item => {
      message += `\nProduto: ${item.Name}\nCódigo Referencia: ${item.ProdReference}\n`;
    });
    return message;
  };
  

  const handleWhatsApp = (url) => {
    if (!validateForm()) {
      return;
    }
    window.open(url, '_blank')
  }

  const message = generateWhatsAppMessage();
  const whatsappNumber = process.env.REACT_APP_WHATSAPPNUMBER;
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className="whatsapp-template">
      <h2>Orçamento via WhatsApp</h2>
      <button onClick={() => handleWhatsApp(whatsappLink)} target="_blank" rel="noopener noreferrer">
        Solicitar orçamento pelo WhatsApp
      </button>
    </div>
  );
};

export default WhatsAppTemplate;

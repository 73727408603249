// src/components/WhatsappButton.js
import React, { useState, useEffect } from 'react';
import './WhatsappButton.css';
import { FaWhatsapp } from 'react-icons/fa';


const WhatsappButton = () => {
  const whatsappNumber = process.env.REACT_APP_WHATSAPPNUMBER;    

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleWhatsApp = () => {    
    const message = "Olá, tenho uma dúvida referente ao seus produtos.";    
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.open(whatsappLink, '_blank');
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div title="Entre em contato através do WhatsApp" alt="Entre em contato através do WhatsApp" onClick={handleWhatsApp} className="whatsapp-button-area">              
          <FaWhatsapp />                  
    </div>
  );
};

export default WhatsappButton;

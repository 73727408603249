// src/pages/Home.js
import React, { useState } from 'react';
import { FaQuestionCircle, FaBook, FaBookOpen ,FaChevronUp, FaChevronDown, FaFlag, FaHandshake, FaTruck, FaBullhorn, FaCalendarAlt, FaCogs, FaPaintBrush, FaPhoneAlt, FaWhatsapp, FaChartBar, FaCameraRetro, FaTint} from 'react-icons/fa'; // Importando ícones FontAwesome
import BannerCarousel from '../components/BannerCarousel';
import SearchBarWithResults from '../components/SearchBarWithResults';
import './Home.css';
import { Link } from 'react-router-dom';


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <div className='question'>
          <div>
            <div>
              <FaQuestionCircle />
            </div>            
            <h3>{question}</h3>
          </div>
          <span>{isOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
        </div>
      </div>
      {isOpen && <div className="faq-answer"><p>{answer}</p></div>}
    </div>
  );
};

const SectionSeparator = () => {
  return (
    <div className="section-separator">
        <img src="images/caneta-separador.png" alt="Separator" />
    </div>
  )
}

const handleSeeMore = (link) => {
  window.location.href = link
}



const Home = () => {
  const faqData = [
    {
      question: 'Quais tipos de brindes personalizados vocês oferecem?',
      answer: 'Oferecemos uma ampla gama de brindes, incluindo canetas, camisetas, chaveiros, cadernos, entre outros, todos personalizáveis com a sua marca.'
    },
    {
      question: 'Como é feito o processo de personalização?',
      answer: 'Utilizamos as melhores técnicas de mercado combinadas com equipamentos de alto nível para garantir que o seu brinde tenha a melhor qualidade de impressão/gravação e durabilidade.'
    },
    {
      question: 'Vocês entregam em todo o Brasil?',
      answer: 'Sim, fazemos entregas em todo o território nacional com prazos rápidos e confiáveis.'
    },
    {
      question: 'Qual o prazo para receber meu pedido?',
      answer: 'O prazo de entrega varia de acordo com a quantidade e o tipo de personalização, mas geralmente entregamos em até 15 dias úteis após a confirmação do pedido.'
    },
    {
      question: 'Posso solicitar uma amostra antes de fazer o pedido?',
      answer: 'Sim, produzimos imagens realistas de como vai ficar seu brinde para que você possa verificar a personalização antes de confirmar seu pedido.'
    }
  ];


  return (
    <div className="home">
      <section id="banner">        
        <BannerCarousel />
      </section>

      <section id="introduction" className="section intro">
        <div className="intro-text">
          <h1><FaBullhorn /> Brindes Personalizados de Alta Qualidade <FaBullhorn className='invert-icon' /></h1>
          <p>
            Somos especialistas em oferecer <strong>brindes empresariais customizados</strong> que destacam a identidade da sua marca.
            Com anos de experiência e presença em grandes eventos, fornecemos soluções criativas e eficazes para promover sua empresa.
          </p>
          <div className='btn-explere-area'>
            <a href="#catalog" className="btn btn-primary">Explore Nosso Catálogo</a>
          </div>
        </div>
        <div className='produtos-destaque'>
          <div className='destaque-esquerda'>
              <div className='destaque-card'>
                <h2>Squeeze's</h2>
                <img src='images/squeeze-destaque.jpg'/>
                <button onClick={() => handleSeeMore('/catalog?search=squeeze')}>Ver mais</button>
              </div>
              <div className='destaque-card'>
                <h2>Mochilas</h2>
                <img src='images/mochilas-destaque.jpg'/>
                <button onClick={() => handleSeeMore('/catalog?search=mochila')}>Ver mais</button>
              </div>
              <div className='destaque-card'>
                <h2>Agendas</h2>
                <img src='images/agendas-destaque.jpg'/>
                <button onClick={() => handleSeeMore('/catalog?search=agenda')}>Ver mais</button>
              </div>
              <div className='destaque-card'>
                <h2>Canecas</h2>
                <img src='images/canecas-destaque.jpg'/>
                <button onClick={() => handleSeeMore('/catalog?search=caneca')}>Ver mais</button>
              </div>
          </div>
          <div className='destaque-direita'>
            
            <div className='destaque-card'>
              <h2>Canetas</h2>
              <img src='images/canetas-destaque.jpg'/>
              <button onClick={() => handleSeeMore('/catalog?search=caneta')}>Ver mais</button>
            </div>

          </div>
          
        </div>
      </section>

      <SectionSeparator />

      <section id="about" className="section about">
        <div className='titleAreaWithIcons'><FaBook /><h2>Nossa História</h2><FaBook className='invert-icon'/></div>
        <div className="timeline">
          <div className="timeline-event">
            <div className="timeline-content">
              <div><FaFlag /><h3> 2005</h3></div>
              <div><p>Fundação da empresa com foco em <strong>brindes personalizados</strong>.</p></div>
            </div>
          </div>
          <div className="timeline-event">
            <div className="timeline-content">
              <div>
                <FaHandshake /><h3>2010</h3>
              </div>
              <div>
                <p>Primeiro grande contrato com uma empresa multinacional.</p>
                </div>
            </div>
          </div>
          <div className="timeline-event">
            <div className="timeline-content">
              <div>
                <FaChartBar /><h3>2015</h3>
              </div>
              <div>
                <p>Expansão dos serviços para incluir <strong>gravação a laser</strong> e <strong>silk screen</strong>.</p>
              </div>
            </div>
          </div>
          <div className="timeline-event">
            <div className="timeline-content">
              <div>
                <FaCalendarAlt /><h3>2020</h3>
              </div>
              <div>
                <p>Presença consolidada em grandes eventos e lançamento de novas linhas de produtos.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionSeparator />

      <section id="services" className="section services">
      <div className='titleAreaWithIcons'> <FaCogs /><h2>Nossos Serviços</h2><FaCogs className='invert-icon'/></div>
        <div className="services-cards">
          <div className="service-card">
            <div> 
              <FaTint /> <h3>Silk Screen</h3>
            </div>
            <div>
              <p>Perfeito para grandes quantidades, o silk screen oferece personalização durável e de alta qualidade.</p>
            </div>            
          </div>
          <div className="service-card">
            <div>
            <FaCameraRetro />  <h3>Transfer</h3>
            </div>
            <div>
              <p>Técnica ideal para personalizações complexas e detalhadas, garantindo uma impressão fiel ao design original.</p>
              </div>
          </div>
          <div className="service-card">
            <div>
              <FaCogs /><h3>Gravação a Laser</h3>
            </div>
            <div>
              <p>Para um acabamento sofisticado, a gravação a laser é precisa e resistente, ideal para metais e materiais rígidos.</p>
            </div>
          </div>
          <div className="service-card">
            <div>
              <FaTruck /><h3>Entrega em Todo o Brasil</h3>
            </div>
            <div>
              <p>Levamos seus brindes até você, onde quer que esteja no Brasil, com prazos rápidos e confiáveis.</p>
            </div>
          </div>
        </div>
      </section>

      <SectionSeparator />

      <section id="catalog" className="section catalog">
        <div className='titleAreaWithIcons'><FaBookOpen /><h2>Nosso Catálogo</h2><FaBookOpen className='invert-icon'/></div>
        <div className='titleAreaWithIcons'><p>Explore nossos produtos e encontre o brinde perfeito para sua empresa.</p></div>
        <SearchBarWithResults />
        <p>Encontre o presente corporativo ideal para sua empresa com nossa seleção exclusiva de brindes personalizados, incluindo canecas, agendas, canetas, mochilas e muito mais.
          <br />Surpreenda seus clientes e colaboradores com produtos de alta qualidade que destacam sua marca. Explore agora e escolha o brinde que vai deixar sua marca ainda mais forte!</p>
        <div><a href="/catalog" className="btn btn-secondary">Acesse Todos os Produtos</a></div>
      </section>

      <SectionSeparator />

      <section id="contact" className="section contact">
        <div className='titleAreaWithIcons'><FaPhoneAlt /><h2>Entre em Contato</h2><FaPhoneAlt className='invert-icon'/></div>
        <div className='titleAreaWithIcons'><p> Tem alguma dúvida? Estamos aqui para te ajudar!</p></div>
        <div className="contact-options">
          <a href="https://wa.me/11993677390?text=Olá, tenho uma dúvida referente ao seus produtos." className="btn btn-whatsapp" target='_blank'><FaWhatsapp /> Fale Conosco pelo WhatsApp</a>
          {/* <p className="no-whatsapp">
            Não tem WhatsApp? <a href="/contact-form">Entre em contato por aqui</a>.
          </p> */}
        </div>

        <div className="faq-section">
          <h3>Perguntas Frequentes</h3>
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>


      </section>
    </div>
  );
};

export default Home;

import { FaCartPlus, FaUser } from 'react-icons/fa';
import React from 'react';
import { useCart } from '../context/CartContext';
import './AddToCartButton.css';
import { useAlert } from '../context/AlertContext';

const AddToCartButton = ({ product }) => {
  const { cart, dispatch } = useCart();
  const isInCart = cart.some(item => item.ProdReference === product.ProdReference);
  const { addAlert } = useAlert(); // Utilizar o contexto de alertas

  const handleAddToCart = () => {
    if (!isInCart) {
      addAlert('Adicionado ao carrinho !', 'success');
      dispatch({ type: 'ADD_TO_CART', product });
    }
  };

  return (
    <button
      className={`add-to-cart-button ${isInCart ? 'in-cart' : ''}`}
      onClick={handleAddToCart}
      disabled={isInCart}
    >
      {isInCart ? 'Adicionado' : `Adicionar`}<FaCartPlus/>
    </button>
  );
};

export default AddToCartButton;

// src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useAlert } from '../context/AlertContext';
import './ProfilePage.css';

const ProfilePage = () => {
  const { authState, logout } = useAuth();
  const { addAlert } = useAlert();
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    tel: '',
    identifier: '',
    dateOfBirth: '',
    address: {
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      postalCode: ''
    },
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authState.token}`
          }
        });

        if (response.ok) {
          const userData = await response.json();
          setFormData((prevData) => ({
            ...prevData,
            ...userData,
            dateOfBirth: userData.dateOfBirth ? userData.dateOfBirth.split('T')[0] : '',
            address: userData.address || {
              street: '',
              neighborhood: '',
              city: '',
              state: '',
              postalCode: ''
            }
          }));
        } else {
          addAlert('Erro ao carregar dados do perfil.', 'error');
        }
      } catch (error) {
        console.error('Erro ao carregar dados do perfil:', error);
        addAlert('Erro ao carregar dados do perfil.', 'error');
      }
    };

    fetchProfile();
  }, [authState.token, addAlert]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        [name]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      addAlert('Senhas não conferem!', 'error');
      return;
    }

    const updateData = { ...formData };
    delete updateData.confirmPassword;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authState.token}`
        },
        body: JSON.stringify(updateData)
      });

      if (response.ok) {
        addAlert('Perfil atualizado com sucesso!', 'success');
      } else {
        addAlert('Erro ao atualizar perfil.', 'error');
      }
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      addAlert('Erro ao atualizar perfil.', 'error');
    }
  };

  return (
    <div className="profile-page">
      <div className='titleAreaWithIcons'><h1>Perfil</h1></div>
      <form className="profile-form" onSubmit={handleSubmit}>
        <label>
          Nome:
          <input type="text" name="nome" value={formData.nome} onChange={handleInputChange} required />
        </label>
        <label>
          E-mail:
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
        </label>
        <label>
          Telefone:
          <input type="tel" name="tel" value={formData.tel} onChange={handleInputChange} required />
        </label>
        <label>
          {formData.idType == 'cpf' ? 'CPF' : 'CNPJ'}
          <input type="text" name={formData.idType == 'cpf' ? 'cpf' : 'cnpj'}  value={formData.identifier} onChange={handleInputChange} required />
        </label>
        <label>
          Data de Nascimento:
          <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleInputChange} required />
        </label>
        <div className='titleAreaWithIcons'><h2>Endereço</h2></div>
        <label>
          Rua:
          <input type="text" name="street" value={formData.address.street} onChange={handleAddressChange} required />
        </label>
        <label>
          Bairro:
          <input type="text" name="neighborhood" value={formData.address.neighborhood} onChange={handleAddressChange} required />
        </label>
        <label>
          Cidade:
          <input type="text" name="city" value={formData.address.city} onChange={handleAddressChange} required />
        </label>
        <label>
          Estado:
          <input type="text" name="state" value={formData.address.state} onChange={handleAddressChange} required />
        </label>
        <label>
          CEP:
          <input type="text" name="postalCode" value={formData.address.postalCode} onChange={handleAddressChange} required />
        </label>
        <label>
          Nova Senha:
          <input type="password" name="password" value={formData.password} onChange={handleInputChange} />
        </label>
        <label>
          Confirmar Nova Senha:
          <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} />
        </label>
        <button type="submit">Atualizar Perfil</button>
      </form>
      <button onClick={logout} className="logout-button">Logout</button>
    </div>
  );
};

export default ProfilePage;

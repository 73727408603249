// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import './App.css';

import Home from './pages/Home';
import CatalogPage from './pages/Catalog/CatalogPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CartPage from './pages/CartPage';
import ProfilePage from './pages/ProfilePage';
import AdminBannersPage from './pages/AdminBannersPage';
import AdminQuotesPage from './pages/AdminQuotesPage';
import UserQuotesPage from './pages/UserQuotesPage';

import CheckoutPage from './pages/CheckoutPage';
import { ProductProvider } from './context/ProductContext';
import { LoadingProvider, useLoading } from './context/LoadingContext';
import { CartProvider } from './context/CartContext';
import { AlertProvider } from './context/AlertContext';
import { AuthProvider } from './context/AuthContext';

import ScrollToTop from './components/ScrollToTop';
import LoadingSpinner from './components/LoadingSpinner';
import ScrollToTopButton from './components/ScrollToTopButton';
import WhatsappButton from './components/WhatsappButton';
import './components/Alerts.css'
import ProtectedRoute from './components/ProtectedRoute'
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';

const AppContent = () => {
  const { loading } = useLoading();

  return (
    <>
      {loading && <LoadingSpinner />}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/catalog" element={<CatalogPage />} />        
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/userquotespage" element={<ProtectedRoute><UserQuotesPage /></ProtectedRoute>} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/admin/banners" element={<ProtectedRoute><AdminBannersPage /></ProtectedRoute>} />        
        <Route path="/admin/adminquotespage" element={<ProtectedRoute><AdminQuotesPage /></ProtectedRoute>} />        
        <Route path="*" element={<Home />} />
        {/* Adicione mais rotas aqui */}
      </Routes>
      <ScrollToTopButton />
      <WhatsappButton />
      <CookieConsent />
      <Footer />
    </>
  );
};

function App() {
  return (
    <AuthProvider>
    <LoadingProvider>
      <AlertProvider>
        <ProductProvider>      
            <CartProvider>
              <Router>
                <ScrollToTop />
                <AppContent />
              </Router>
            </CartProvider>      
        </ProductProvider>
      </AlertProvider>
    </LoadingProvider>
    </AuthProvider>
  );
}

export default App;

import React from 'react';

import { useNavigate } from 'react-router-dom';
import './ProductCard.css';
import AddToCartButton from './AddToCartButton';

const ProductCard = ({ product }) => {
  
  /**
 * Remove o prefixo até o ponto e os espaços em branco no início e no final.
 * @param {string} str - A string original com o prefixo.
 * @returns {string} - A string sem o prefixo e sem espaços em branco no início e no final.
 */
  const removePrefix = (str) => {
    // Encontrar o índice do ponto
    const index = str.indexOf('.');
    
    // Se não encontrar o ponto, retornar a string original, trimando os espaços em branco
    if (index === -1) return str.trim();
    
    // Retornar a parte da string após o ponto, trimando os espaços em branco
    return str.substring(index + 1).trim();
  };


  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${product.ProdReference}`);
  };

  return (
    <div className="product-card">
      <img onClick={handleClick} src={`https://www.spotgifts.com.br/fotos/produtos/${product.MainImage}`} alt={product.name} />
      <h3>{removePrefix(product.Name)}</h3>
      {/* <h3>{product.TypeCode} - {product.Type}</h3>
      <h3>{product.SubTypeCode} - {product.SubType}</h3>      
      <h3>{product.Catalogs}</h3>     
      <h3>{product.Colors}</h3>      */}
      <div className='product-card-action-butons'>
        <span className='product-more-details'><a href='#' onClick={handleClick} >Mais detalhes</a></span>
        <AddToCartButton product={product}/>
      </div>
    </div>
  );
};

export default ProductCard;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import './CartPage.css';
import { GiShoppingCart } from "react-icons/gi";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import SearchBarWithResults from '../components/SearchBarWithResults';
import { FaClipboardList } from "react-icons/fa";


const CartPage = () => {
  const removePrefix = (str) => {
    const index = str.indexOf('.');
    if (index === -1) return str.trim();
    return str.substring(index + 1).trim();
  };

  const { cart, dispatch } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    getUpdatedCart();
  }, [cart]);

  const getUpdatedCart = () => {
    cart.forEach(item => {
      if (!item.quantity || item.quantity < 1) {
        dispatch({ type: 'UPDATE_QUANTITY', product: item, quantity: 100 });
      }
    });
  };

  const handleRemoveFromCart = (product) => {
    dispatch({ type: 'REMOVE_FROM_CART', product });
  };

  const handleQuantityChange = (product, quantity) => {
    dispatch({ type: 'UPDATE_QUANTITY', product, quantity: parseInt(quantity, 10) });
  };

  const handleColorChange = (product, color) => {
    dispatch({ type: 'UPDATE_COLOR', product, color });
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div className="cart-page">
      <div className="cart-area-left">
        <div className='titleAreaWithIcons'><GiShoppingCart /><h1>Seu Carrinho</h1><GiShoppingCart className='invert-icon'/></div>
        {cart.length === 0 ? (
          <div className='empty-cart-page'>
            <div className='empty-cart-area'>
              <div className='titleAreaWithIcons'> <p>Seu carrinho está vazio.</p> </div>
              <div className='empty-cart'> <MdOutlineRemoveShoppingCart /></div>          
            </div>
            <SearchBarWithResults />
          </div>
        ) : (
          <div className="cart-items">
            {cart.map(item => (
              <div key={item.ProdReference} className="cart-item">
                <div className='cart-img-area'>
                  <img src={`https://www.spotgifts.com.br/fotos/produtos/${item.MainImage}`} alt={item.ProdName} />
                </div>
                <div className="cart-item-details">
                  <div className='name-description'>
                    <h2>{removePrefix(item.Name)}</h2>
                    <p>{item.ShortDescription}</p>
                  </div>
                  <div className='action-cart-item'>
                    <div className='qtd-item-cart'>
                      <span>Quantidade:</span>
                      <input
                        type="number"
                        value={item.quantity || 100}
                        onChange={(e) => handleQuantityChange(item, e.target.value)}
                        min="100"
                      />
                    </div>
                    {item.Colors && item.Colors.length > 0 && (
                      <div className='color-item-cart'>
                        <span>Cor:</span>
                        <select
                          value={item.selectedColor || item.Colors.split(', ')[0]}
                          onChange={(e) => handleColorChange(item, e.target.value)}
                        >
                          {item.Colors.split(', ').map((color, index) => (
                            <option key={index} value={color}>{color}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    <button className="btn-remove-cart" onClick={() => handleRemoveFromCart(item)}>Remover</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {cart.length > 0 && (
        <div className="cart-area-right">
          <div className='titleAreaWithIcons'><FaClipboardList /><h1>Resumo do Pedido</h1><FaClipboardList /></div>
          <div className="cart-page-summary">
            
            <ul>
              {cart.map((item, index) => (
                <li key={item.ProdReference}>
                  <div className='cart-summary-index'>
                    <h3>{index+1}</h3>
                  </div>
                  <div>
                    <p><span>Produto: </span>{removePrefix(item.Name)}</p>
                    <div className='cart-mesma-linha'>
                      <p><span>Quantidade:</span> {item.quantity}</p>
                      <p><span>Cor:</span> {item.selectedColor || item.Colors.split(', ')[0]}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className='finalizar-pedido-button'>
              <button className="checkout-button" onClick={handleCheckout}>Finalizar Pedido</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;

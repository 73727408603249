import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductContext from '../context/ProductContext'; 
import AddToCartButton from '../components/AddToCartButton';
import './ProductDetailsPage.css';
import RelatedProductsCarousel from '../components/RelatedProductsCarousel';

const ProductDetailsPage = () => {

  /**
   * Remove o prefixo até o ponto e os espaços em branco no início e no final.
   * @param {string} str - A string original com o prefixo.
   * @returns {string} - A string sem o prefixo e sem espaços em branco no início e no final.
   */
  const removePrefix = (str) => {
    // Encontrar o índice do ponto
    const index = str.indexOf('.');
    
    // Se não encontrar o ponto, retornar a string original, trimando os espaços em branco
    if (index === -1) return str.trim();
    
    // Retornar a parte da string após o ponto, trimando os espaços em branco
    return str.substring(index + 1).trim();
  };

  
  const { id } = useParams();
  const { products } = useContext(ProductContext);
  const product = products.find(p => p.ProdReference === id);
  const navigate = useNavigate();
  const [zoomStyle, setZoomStyle] = useState({ display: 'none' });
  const [mainImage, setMainImage] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (product && product.MainImage) {
      setMainImage(product.MainImage);
    }
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [product]);

  if (!product) {
    return <p>Produto não encontrado. Retorne à página inicial.</p>;
  }

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setZoomStyle({
      display: 'block',
      backgroundImage: `url(https://www.spotgifts.com.br/fotos/produtos/${mainImage})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: '200%',
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({ display: 'none' });
  };

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  const imageList = (product.AllImageList || '')
    .split(',')
    .map(img => img.trim())
    .filter(img => img !== '');

  return (
    <div className="product-details">
      <div className='product-header'>
        <button className="back-button" onClick={() => navigate('/catalog')}>Voltar ao Catálogo</button> 
        <AddToCartButton product={product} />
      </div>
      
      <div className="product-details-content">
        <div className="image-column">
          <div className="image-container" >
            <div className="image-area" onMouseMove={!isMobile ? handleMouseMove : null} onMouseLeave={!isMobile ? handleMouseLeave : null}>
              {mainImage && (
                <img src={`https://www.spotgifts.com.br/fotos/produtos/${mainImage}`} alt={product.ProdName} />
              )}
              <div className="zoom-image" style={zoomStyle}></div>
            </div>
            <div className="thumbnail-area">
              {imageList.length > 0 && (
                <div className="thumbnail-container">
                  {imageList.map((image, index) => (
                    <img 
                      key={index}
                      src={`https://www.spotgifts.com.br/fotos/produtos/${image}`} 
                      alt={`Thumbnail ${index + 1}`}
                      className="thumbnail"
                      onClick={() => handleThumbnailClick(image)}
                    />
                  ))}
                </div>
              )}
            </div>            
          </div>
          <div className='add-cart-product-image'>
            <AddToCartButton product={product} />
          </div>
        </div>

        <div className="info-column">
        <h2>{removePrefix(product.Name)}</h2>

          <div className="product-info">
            <p><b>Descrição:</b><br /> {product.Description}</p>                       
            {product.Materials ? <p><b>Meterial:</b><br /> {product.Materials}</p> : ""}
            {product.BoxWeightKG ? <p><b>Peso do Produto (Kg.):</b><br /> {product.BoxWeightKG}</p> : ""}
            {product.BoxQuantity ? <p><b>Quantidade por Caixa (Un.):</b><br /> {product.BoxQuantity}</p> : ""}
            {product.Colors ? <p><b>Cores:</b><br /> {product.Colors}</p> : ""}
            {product.ProdReference ? <p><b>Código:</b><br /> CN-{product.ProdReference}-COMBR</p> : ""}            

            {/* <p>Caixa Exterior (Un.): {product.ExteriorBoxQty}</p>
            <p>Caixa interior (Un.): {product.InteriorBoxQty}</p>
            <p>NCMS: {product.NCMS}</p>
            <p>Certificados: {product.Certificates}</p> */}
          </div>

          {product.CustomizationTypes && (
            <div className="customization-section">
              <h3><b>Customização</b></h3>
              {product.CustomizationTypes ? <p><b>Tipos de Customização:</b><br />{product.CustomizationTypes}</p> : ""}            
              {product.CustomizationDefaultType ? <p><b>Tipo Padrão:</b><br />{product.CustomizationDefaultType}</p> : ""}            
              {product.ProductComposedLocations ? <p><b>Local:</b><br />{product.ProductComposedLocations}</p> : ""}            
              {product.ProductComponentDefaultLocationAreaMM ? <p><b>Área:</b><br />{product.ProductComponentDefaultLocationAreaMM} MM</p> : ""}

              
              {/* <p>Tabela Padrão: {product.CustomizationDefaultTable}</p>
              <p>Opções de Tabela: {product.CustomizationTableOptions}</p>
              <p>Customização Padrão: {product.CustomizationDefault}</p>
              <p>Máximo de Cores na Tabela Padrão: {product.CustomizationDefaultTableMaxColors}</p>
              <p>Custo de Manuseio Padrão: {product.CustomizationDefaultHandlingCosts}</p> */}
              {/* {product.CustomizationDefaultPrintingLines && (
                <img 
                  src={`https://www.spotgifts.com.br/fotos/produtos/${product.CustomizationDefaultPrintingLines}`} 
                  alt="Linhas de Impressão Padrão"
                  className="customization-image"
                />
              )} */}
            </div>
          )}
        </div>
      </div>
      <div className='related-products'>
        <RelatedProductsCarousel currentProduct={product}/>
      </div>
    </div>
  );
};

export default ProductDetailsPage;

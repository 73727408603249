import React from 'react';
import './Filters.css';

const Filters = ({ filters, setFilters, availableFilters, expandedFilters, toggleFilterSection }) => {
  const handleFilterChange = (category, value) => {
    if (category === 'colors' || category === 'catalogs' || category === 'types' || category === 'subTypes') {
      setFilters(prevFilters => {
        const isInFilters = prevFilters[category]?.includes(value);
        return {
          ...prevFilters,
          [category]: isInFilters
            ? prevFilters[category].filter(item => item !== value)
            : [...(prevFilters[category] || []), value],
        };
      });
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        [category]: prevFilters[category] === value ? '' : value,
      }));
    }
  };

  const getCategoryDisplayName = (filterCategory) => {
    switch (filterCategory) {
      case 'types':
        return 'Tipos';
      case 'subTypes':
        return 'Subtipos';
      case 'colors':
        return 'Cores';
      case 'catalogs':
        return 'Catálogos';
      default:
        return filterCategory.charAt(0).toUpperCase() + filterCategory.slice(1);
    }
  };

  return (
    <div className="filters-container">
      {Object.keys(availableFilters).map(filterCategory => (        
        <div key={filterCategory} className="filter-section">
          <div className="filter-header" onClick={() => toggleFilterSection(filterCategory)}>
            {/* <span>{filterCategory.charAt(0).toUpperCase() + filterCategory.slice(1)}</span> */}
            <span>{getCategoryDisplayName(filterCategory)}</span>
            <span>{expandedFilters[filterCategory] ? '-' : '+'}</span>
          </div>
          {expandedFilters[filterCategory] && (
            <div className="filter-options">
              {Object.entries(availableFilters[filterCategory]).map(([option, { code, count }]) => (
                <div key={option} className="filter-option">
                  <input
                    type={['types', 'subTypes', 'colors', 'catalogs'].includes(filterCategory) ? 'checkbox' : 'radio'}
                    id={`${filterCategory}-${code}`}
                    name={filterCategory}
                    value={code}
                    checked={filters[filterCategory]?.includes(code)}
                    onChange={() => handleFilterChange(filterCategory, code)}
                  />
                  <label htmlFor={`${filterCategory}-${code}`}>
                    {option}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Filters;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useAlert } from '../context/AlertContext';
import './UserQuotesPage.css';
import { FaBoxOpen} from 'react-icons/fa';
import { FaTableList } from "react-icons/fa6";

const UserQuotesPage = () => {
  const { authState } = useAuth();
  const { addAlert } = useAlert();
  const [quotes, setQuotes] = useState([]);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasFetched, setHasFetched] = useState(false);
  const quotesPerPage = 5;

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentosuser`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authState.token}`
          }
        });

        if (response.ok) {
          const quotesData = await response.json();
          if (Array.isArray(quotesData)) {
            if (quotesData.length === 0) {
              addAlert('Nenhum orçamento encontrado.', 'info');
            }
            setQuotes(quotesData);
          } else {
            addAlert('Nenhum orçamento encontrado.', 'info');
          }
          setHasFetched(true);
        } else if (response.status === 403) {
          addAlert('Acesso negado. Você não tem permissão para visualizar estes orçamentos.', 'error');
          setHasFetched(true);
        } else {
          addAlert('Erro ao carregar orçamentos.', 'error');
          setHasFetched(true);
        }
      } catch (error) {
        console.error('Erro ao carregar orçamentos:', error);
        addAlert('Erro ao carregar orçamentos.', 'error');
        setHasFetched(true);
      }
    };

    if (authState.isAuthenticated && !hasFetched) {
      fetchQuotes();
    }
  }, [authState, addAlert, hasFetched]);

  const indexOfLastQuote = currentPage * quotesPerPage;
  const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
  const currentQuotes = quotes.slice(indexOfFirstQuote, indexOfLastQuote);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const renderProductCard = (product, index) => {
    return (
      <div key={`${product.ProdReference}-${index}`} className="quote-product-card">
        {product.MainImage ? (
          <img src={`https://www.spotgifts.com.br/fotos/produtos/${product.MainImage}`} alt={product.Name} />
        ) : (
          <FaBoxOpen size={64} />
        )}
        <div className="quote-product-details">
          <h3>{product.Name}</h3>
          <p>{product.ShortDescription}</p>
          <p>Quantidade: {product.quantity}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="user-quotes-page">
      <h1>Meus Orçamentos</h1>
      {selectedQuote ? (
        <div className="quote-details">
          <h2>Detalhes do Orçamento</h2>
          <p><strong>Nome:</strong> {selectedQuote.nome}</p>
          <p><strong>Email:</strong> {selectedQuote.email}</p>
          <p><strong>Telefone:</strong> {selectedQuote.telefone}</p>
          <p><strong>Descrição:</strong> {selectedQuote.descricao}</p>
          <div className="quote-products">
            {selectedQuote.cart.map((product, index) =>
              renderProductCard(product, index)
            )}
          </div>
          <button onClick={() => setSelectedQuote(null)}>Voltar</button>
        </div>
      ) : (
        <div className="quotes-list">
          {quotes.length > 0 ? (
            currentQuotes.map((quote, index) => (
              <div key={`${quote._id}-${index}`} className="quote-summary" >
                <div className='description-summary'>
                  <h3>Orçamento: <span>#{quote._id}</span></h3>
                  {quote.descricao.length > 0 ? (<p>{quote.descricao}</p>) : (<p className='empty-description'>Sem descrição...</p>)}
                  
                  <p>Status: <span className={quote.status}>{quote.status}</span></p>
                </div>
                <div className='icon-summary' onClick={() => setSelectedQuote(quote)}>
                  <FaTableList size={64}/>
                  <p>Abrir</p>
                </div>
              </div>
            ))
          ) : (
            <p>Nenhum orçamento encontrado.</p>
          )}
          {quotes.length > 0 && (
            <div className="pagination">
              {[...Array(Math.ceil(quotes.length / quotesPerPage)).keys()].map((number) => (
                <button key={`page-${number + 1}`} onClick={() => handlePageChange(number + 1)}>
                  {number + 1}
                </button>
              ))}
            </div>
          )}
        </div>        
      )}
    </div>
    
  );
};

export default UserQuotesPage;

// src/components/SearchBarWithResults.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import './SearchBarWithResults.css';
import ProductContext from '../context/ProductContext';

const SearchBarWithResults = () => {
  const { products } = useContext(ProductContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setFilteredProducts([]);
    } else {
      const filtered = products.filter((product) =>
        product.Name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
    setSearchTerm('');
    setFilteredProducts([]);
  };

  return (
    <div className="search-bar-with-results">
      <div className="search-input-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Buscar produtos..."
        />
        <FaSearch className="search-icon" />
      </div>
      {filteredProducts.length > 0 && 
      (
        <div className="search-results">
          {filteredProducts.slice(0, 4).map((product) => (
            <div key={product.ProdReference} className="search-result-item">
              <img
                src={`https://www.spotgifts.com.br/fotos/produtos/${product.MainImage}`}
                alt={product.Name}
                className="search-result-image"
              />
              <div className="search-result-info">
                <p>{product.Name}</p>
                <button onClick={() => handleProductClick(product.ProdReference)}>
                  Acessar
                </button>
              </div>
            </div>
          ))}
          {filteredProducts.length > 4 && (
            <div className="search-result-scroll">
              {filteredProducts.slice(4).map((product) => (
                <div key={product.ProdReference} className="search-result-item">
                  <img
                    src={`https://www.spotgifts.com.br/fotos/produtos/${product.MainImage}`}
                    alt={product.Name}
                    className="search-result-image"
                  />
                  <div className="search-result-info">
                    <p>{product.Name}</p>
                    <button onClick={() => handleProductClick(product.ProdReference)}>
                      Acessar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )
     
      
      }
    </div>
  );
};

export default SearchBarWithResults;

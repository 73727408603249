import React, { createContext, useState, useContext, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    token: null,
  });

  useEffect(() => {
    const storedAuthState = localStorage.getItem('authState');
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState);
      if (isTokenExpired(parsedAuthState.token)) {
        logout(); // Expira sessão se token estiver expirado
      } else {
        setAuthState(parsedAuthState);
      }
    }
  }, []);

  const isTokenExpired = (token) => {
    if (!token) return true;
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
  };

  const login = (user, token) => {
    const newAuthState = {
      isAuthenticated: true,
      user: user,
      token: token,
    };
    setAuthState(newAuthState);
    localStorage.setItem('authState', JSON.stringify(newAuthState));
  };

  const logout = () => {
    const newAuthState = {
      isAuthenticated: false,
      user: null,
      token: null,
    };
    setAuthState(newAuthState);
    localStorage.removeItem('authState');
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (authState.token && isTokenExpired(authState.token)) {
        logout();
      }
    }, 60000); // Verifica a cada 60 segundos

    return () => clearInterval(intervalId);
  }, [authState.token]);

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useAlert } from '../context/AlertContext';
import './AdminBannersPage.css';
import { useLoading } from '../context/LoadingContext';



const AdminBannersPage = () => {
  const { setLoading } = useLoading();

  const { authState } = useAuth();
  const { addAlert } = useAlert();
  const [banners, setBanners] = useState([]);
  const [newBanner, setNewBanner] = useState({
    title: '',
    description: '',
    image: '',
    startDate: '',
    endDate: '',
    order: '',
    link: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [bannerToEdit, setBannerToEdit] = useState(null);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banners/admin`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authState.token}`
          }
        });

        if (response.ok) {
          const bannerData = await response.json();
          setBanners(bannerData);
        } else {
          addAlert('Erro ao carregar banners.', 'error');
        }
      } catch (error) {
        console.error('Erro ao carregar banners:', error);
        addAlert('Erro ao carregar banners.', 'error');
      }
    };

    fetchBanners();
  }, [authState.token, addAlert]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBanner({ ...newBanner, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setNewBanner({ ...newBanner, image: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleAddBanner = async (e) => {
    setLoading(true);

    e.preventDefault();

    // Validação adicional
    if (new Date(newBanner.endDate) < new Date(newBanner.startDate)) {
      addAlert('A data de término não pode ser menor que a data de início.', 'error');
      setLoading(false);

      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authState.token}`
        },
        body: JSON.stringify(newBanner)
      });

      if (response.ok) {
        const addedBanner = await response.json();
        setBanners([...banners, addedBanner]);
        addAlert('Banner adicionado com sucesso!', 'success');
        setNewBanner({ title: '', description: '', image: '', startDate: '', endDate: '', order: '', link: '' });
        

        //localStorage.setItem('banners', JSON.stringify([...banners, addedBanner])); // Atualizar cache
      } else {
        addAlert('Erro ao adicionar banner.', 'error');
      }
    } catch (error) {
      console.error('Erro ao adicionar banner:', error);
      addAlert('Erro ao adicionar banner.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleEditBanner = (banner) => {
    setNewBanner({
      title: banner.title,
      description: banner.description,
      image: banner.image,
      startDate: banner.startDate.split('T')[0], // Formatação para campo de data
      endDate: banner.endDate.split('T')[0],
      order: banner.order,
      link: banner.link,
    });
    setBannerToEdit(banner._id);
    setIsEditing(true);

    // Rolar para o topo da página
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validação adicional
    if (new Date(newBanner.endDate) < new Date(newBanner.startDate)) {
      addAlert('A data de término não pode ser menor que a data de início.', 'error');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banners/${bannerToEdit}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authState.token}`
        },
        body: JSON.stringify(newBanner)
      });

      if (response.ok) {
        const updatedBanner = await response.json();
        setBanners(banners.map(b => (b._id === bannerToEdit ? updatedBanner : b)));
        addAlert('Banner atualizado com sucesso!', 'success');
        setNewBanner({ title: '', description: '', image: '', startDate: '', endDate: '', order: '', link: '' });
        setIsEditing(false);
        setBannerToEdit(null);
        //localStorage.setItem('banners', JSON.stringify(banners.map(b => (b._id === bannerToEdit ? updatedBanner : b)))); // Atualizar cache
      } else {
        addAlert('Erro ao atualizar banner.', 'error');
      }
    } catch (error) {
      console.error('Erro ao atualizar banner:', error);
      addAlert('Erro ao atualizar banner.', 'error');
    }
    finally{
      setLoading(false);
    }
  };

  const handleDeleteBanner = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/banners/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authState.token}`
        }
      });

      if (response.ok) {
        const updatedBanners = banners.filter(banner => banner._id !== id);
        setBanners(updatedBanners);
        addAlert('Banner excluído com sucesso!', 'success');
        //localStorage.setItem('banners', JSON.stringify(updatedBanners)); // Atualizar cache
      } else {
        addAlert('Erro ao excluir banner.', 'error');
      }
    } catch (error) {
      console.error('Erro ao excluir banner:', error);
      addAlert('Erro ao excluir banner.', 'error');
    }
  };

  return (
    <div className="admin-banners-page">
      <div className='admin-banner-form-area'>
        <div>
          <h1>Gerenciar Banners</h1>
          {!isEditing ? <h2>Novo banner !</h2> : <h2>Editar banner !</h2>}
        </div>

        <form className="banner-form" onSubmit={isEditing ? handleSaveChanges : handleAddBanner}>
          <label>
            Título:
            <input type="text" name="title" value={newBanner.title} onChange={handleInputChange} required />
          </label>
          <label>
            Descrição:
            <input type="text" name="description" value={newBanner.description} onChange={handleInputChange} required />
          </label>
          <label>
            Imagem:
            <input type="file" name="image" onChange={handleFileChange} required={!isEditing} />
          </label>
          <label>
            Data de Início:
            <input type="date" className='one-quarter-size' name="startDate" value={newBanner.startDate} onChange={handleInputChange} required />
          </label>
          <label>
            Data de Fim:
            <input type="date" className='one-quarter-size' name="endDate" value={newBanner.endDate} onChange={handleInputChange} required />
          </label>
          <label>
            Ordem:
            <input type="number" name="order" value={newBanner.order} onChange={handleInputChange} required />
          </label>
          <label>
            Link:
            <input type="url" name="link" value={newBanner.link} onChange={handleInputChange} required />
            <span>Ex.: https://www.canetas.com.br</span>
          </label>
          <button type="submit">{isEditing ? 'Salvar Alterações' : 'Adicionar Banner'}</button>
        </form>
      </div>
      <div className="banner-list">
        {banners.map(banner => (
          <div key={banner._id} className="banner-item">
            <div className='admin-image-area'>
              <img src={banner.image} alt={banner.title} />
            </div>
            <div>
              <h2>{banner.title}</h2>
              <p>{banner.description}</p>
              <p>Início: {new Date(banner.startDate).toLocaleDateString()}</p>
              <p>Fim: {new Date(banner.endDate).toLocaleDateString()}</p>
              <p>Ordem: {banner.order}</p>
              <p><a href={banner.link} target="_blank" rel="noopener noreferrer">Ver Link</a></p>
              <button className='edit-button' onClick={() => handleEditBanner(banner)}>Editar</button>
              <button onClick={() => handleDeleteBanner(banner._id)}>Excluir</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBannersPage;

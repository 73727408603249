import React, { createContext, useState, useEffect } from 'react';
import { useLoading } from './LoadingContext';


const ProductContext = createContext();


export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const { setLoading } = useLoading();

  useEffect(() => {
    // Função para buscar os produtos da API e armazená-los no estado global
    setLoading(true);
    const fetchProducts = async () => {      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/products`);
        const data = await response.json();
        setProducts(data.Products || []); // Certifique-se de acessar o array de produtos corretamente
      } catch (error) {
        const msg = 'Erro ao buscar produtos';
        console.error(msg, error);        
      }finally{
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;

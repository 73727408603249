import React, { createContext, useState, useContext } from 'react';
import { FaBell } from 'react-icons/fa';

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (message, type = 'info') => {
    const id = new Date().getTime();
    setAlerts((prevAlerts) => [...prevAlerts, { id, message, type, progress: 100 }]);
    setTimeout(() => removeAlert(id), 3000); // Remover o alerta após 2,5 segundos

    // Iniciar a animação da barra de progresso
    setTimeout(() => {
      setAlerts((prevAlerts) =>
        prevAlerts.map((alert) =>
          alert.id === id ? { ...alert, progress: 0 } : alert
        )
      );
    }, 50);
  };

  const removeAlert = (id) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  };

  return (
    <AlertContext.Provider value={{ addAlert }}>
      {children}
      <div className="alerts-container">
        {alerts.map((alert) => (
          <div key={alert.id} className={`alert ${alert.type}`}>
            <div className="alert-content">
              <FaBell />
              {alert.message}
              <button className="close-btn" onClick={() => removeAlert(alert.id)}>x</button>
            </div>
            <div className="progress-bar" style={{ width: `${alert.progress}%` }} />
          </div>
        ))}
      </div>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);

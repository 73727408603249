// src/pages/AdminQuotesPage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useAlert } from '../context/AlertContext';
import './AdminQuotesPage.css';

const AdminQuotesPage = () => {
  const { authState } = useAuth();
  const { addAlert } = useAlert();
  const [quotes, setQuotes] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const quotesPerPage = 10;

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentos`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authState.token}`
          }
        });

        if (response.ok) {
          const quotesData = await response.json();
          setQuotes(quotesData);
        } else {
          addAlert('Erro ao carregar orçamentos.', 'error');
        }
      } catch (error) {
        console.error('Erro ao carregar orçamentos:', error);
        addAlert('Erro ao carregar orçamentos.', 'error');
      }
    };

    if (authState.isAuthenticated) {
      fetchQuotes();
    }
  }, [authState, addAlert]);

  const filteredQuotes = filterStatus
    ? quotes.filter(quote => quote.status === filterStatus)
    : quotes;

  const indexOfLastQuote = currentPage * quotesPerPage;
  const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
  const currentQuotes = filteredQuotes.slice(indexOfFirstQuote, indexOfLastQuote);

  const handleStatusChange = async (quoteId, status) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentos/${quoteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authState.token}`
        },
        body: JSON.stringify({ status })
      });

      if (response.ok) {
        setQuotes(quotes.map(quote => quote._id === quoteId ? { ...quote, status } : quote));
        addAlert('Status atualizado com sucesso!', 'success');
      } else {
        addAlert('Erro ao atualizar status.', 'error');
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
      addAlert('Erro ao atualizar status.', 'error');
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="admin-quotes-page">
      <h1>Todos os Orçamentos</h1>
      <div className="filter">
        <label>Status:</label>
        <select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
          <option value="">Todos</option>
          <option value="Enviado">Enviado</option>
          <option value="Contatado">Contatado</option>
        </select>
      </div>
      <div className="quotes-list">
        {currentQuotes.map((quote) => (
          <div key={quote._id} className="quote-summary">
            <h3>Orçamento #{quote._id}</h3>
            <p>{quote.descricao}</p>
            <p>Status: {quote.status}</p>
            <p>Cor: {quote.selectedColor}</p>
            <select onChange={(e) => handleStatusChange(quote._id, e.target.value)} value={quote.status}>
              <option value="Enviado">Enviado</option>
              <option value="Contatado">Contatado</option>
            </select>
          </div>
        ))}
        <div className="pagination">
          {[...Array(Math.ceil(filteredQuotes.length / quotesPerPage)).keys()].map((number) => (
            <button key={number + 1} onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminQuotesPage;

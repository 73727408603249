import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom'; // Importando o hook useLocation
import ProductCard from '../../components/ProductCard';
import Filters from '../../components/Filters';
import SearchBar from '../../components/SearchBar';
import './CatalogPage.css';
import ProductContext from '../../context/ProductContext';
import { useLoading } from '../../context/LoadingContext';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; 

const CatalogPage = () => {
  const { setLoading } = useLoading();
  const { products } = useContext(ProductContext);
  const location = useLocation(); // Hook do React Router para obter a localização atual

  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [filters, setFilters] = useState({
    types: [],
    subTypes: [],
    colors: [],
    // catalogs: [],
    searchQuery: '',
  });
  const [availableFilters, setAvailableFilters] = useState({
    types: [],
    subTypes: [],
    colors: [],
    // catalogs: []
  });
  const [expandedFilters, setExpandedFilters] = useState({
    main: true,
    types: false,
    subTypes: false,
    colors: false,
    // catalogs: false
  });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const initialLoadCount = 20;
  const loadCount = 12;
  const marginOfSafety = 200;

  // Função para obter o parâmetro de pesquisa da URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQueryFromURL = searchParams.get('search');
    
    if (searchQueryFromURL) {
      setFilters(prevFilters => ({ ...prevFilters, searchQuery: searchQueryFromURL }));
    }
  }, [location.search]);

  useEffect(() => {
    if (products.length > 0) {
      setAllProducts(products);
      setFilteredProducts(products);
      setDisplayedProducts(products.slice(0, initialLoadCount));
      setHasMore(products.length > initialLoadCount);
      updateAvailableFilters(products);
    }
  }, [products]);

  const normalizeColor = (color) => {
    let normalizedColor = color.toLowerCase().trim();
    if (normalizedColor.includes('azul')) {
      normalizedColor = 'azul';
    } else if (normalizedColor.includes('vermelho')) {
      normalizedColor = 'vermelho';
    } else if (normalizedColor.includes('verde')) {
      normalizedColor = 'verde';
    } else if (normalizedColor.includes('amarelo')) {
      normalizedColor = 'amarelo';
    } else if (normalizedColor.includes('branco')) {
      normalizedColor = 'branco';
    } else if (normalizedColor.includes('cinza')) {
      normalizedColor = 'cinza';
    } else if (normalizedColor.includes('marron')) {
      normalizedColor = 'marron';
    } else if (normalizedColor.includes('dourado')) {
      normalizedColor = 'dourado';
    } else if (normalizedColor.includes('natural')) {
      normalizedColor = 'natural';
    }
    return normalizedColor;
  };

  const getFilterCounts = (products, key, displayKey) => {
    const counts = {};
    products.forEach(product => {
      let values = product[key] ? product[key].split(', ') : [];
      if (key === 'Colors') {
        values = values.flatMap(value => normalizeColor(value).split(', '));
      }
      values.forEach(value => {
        if (value) {
          const displayValue = key === 'Colors' ? value.charAt(0).toUpperCase() + value.slice(1) : product[displayKey];
          if (counts[displayValue]) {
            counts[displayValue].count += 1;
          } else {
            counts[displayValue] = { code: value, count: 1 };
          }
        }
      });
    });
    return counts;
  };

  const updateAvailableFilters = (products) => {
    const types = getFilterCounts(products, 'TypeCode', 'Type');
    const subTypes = getFilterCounts(products, 'SubTypeCode', 'SubType');
    const colors = getFilterCounts(products, 'Colors', 'Colors');
    // const catalogs = getFilterCounts(products, 'Catalogs', 'Catalogs');

    setAvailableFilters({
      types,
      subTypes,
      colors,
      // catalogs
    });
  };

  const applyFilters = useCallback(() => {
    let filtered = allProducts;

    if (filters.types.length > 0) {
      filtered = filtered.filter(product => filters.types.includes(product.TypeCode));
    }
    if (filters.subTypes.length > 0) {
      filtered = filtered.filter(product => filters.subTypes.includes(product.SubTypeCode));
    }
    if (filters.colors.length > 0) {
      filtered = filtered.filter(product => {
        const productColors = product.Colors ? product.Colors.split(', ') : [];
        return filters.colors.every(color => productColors.map(normalizeColor).includes(color));
      });
    }
    // if (filters.catalogs.length > 0) {
    //   filtered = filtered.filter(product => {
    //     const productCatalogs = product.Catalogs ? product.Catalogs.split(', ') : [];
    //     return filters.catalogs.every(catalog => productCatalogs.includes(catalog));
    //   });
    // }
    if (filters.searchQuery) {
      filtered = filtered.filter(product =>
        (product.ProdName && product.ProdName.toLowerCase().includes(filters.searchQuery.toLowerCase())) ||
        (product.Description && product.Description.toLowerCase().includes(filters.searchQuery.toLowerCase()))
      );
    }

    setFilteredProducts(filtered);
    setDisplayedProducts(filtered.slice(0, page * loadCount));
    setHasMore(filtered.length > page * loadCount);

    updateAvailableFilters(filtered);
  }, [allProducts, filters, page]);

  useEffect(() => {
    applyFilters();
  }, [filters, page, applyFilters]);

  const loadMoreProducts = () => {
    if (!isLoading && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + marginOfSafety >= document.documentElement.scrollHeight) {
        loadMoreProducts();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreProducts]);

  const clearAllFilters = () => {
    setFilters({
      types: [],
      subTypes: [],
      colors: [],
      // catalogs: [],
      searchQuery: '',
    });
  };

  const removeFilter = (filterCategory, value) => {
    setFilters(prevFilters => {
      if (Array.isArray(prevFilters[filterCategory])) {
        return {
          ...prevFilters,
          [filterCategory]: prevFilters[filterCategory].filter(item => item !== value)
        };
      } else {
        return {
          ...prevFilters,
          [filterCategory]: ''
        };
      }
    });
  };

  const getFilterName = (filterCategory, value) => {
    if (!availableFilters[filterCategory]) return value;
    return Object.keys(availableFilters[filterCategory]).find(
      key => availableFilters[filterCategory][key].code === value
    ) || value;
  };

  const toggleFilterSection = (section) => {
    setExpandedFilters(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isMobileView = window.innerWidth <= 768;

  const isAnyFilterApplied = Object.values(filters).some(value => (Array.isArray(value) ? value.length > 0 : value));

  return (
    <div className="catalog-page">
      {isMobileView ? (
        <div className="mobile-filters">
          <SearchBar searchQuery={filters.searchQuery} setSearchQuery={(query) => setFilters(prevFilters => ({ ...prevFilters, searchQuery: query }))} />
          <div className="filter-header" onClick={() => toggleFilterSection('main')}>
            <span>Filtrar Por</span>
            <span>{expandedFilters.main ? '-' : '+'}</span>
          </div>
          {expandedFilters.main && (
            <Filters
              filters={filters}
              setFilters={setFilters}
              availableFilters={availableFilters}
              expandedFilters={expandedFilters}
              toggleFilterSection={toggleFilterSection}
            />            
          )}          
        </div>
      ) : (
        <div className={`filters-column ${isSidebarOpen ? 'open' : 'closed'}`}>
          <SearchBar searchQuery={filters.searchQuery} setSearchQuery={(query) => setFilters(prevFilters => ({ ...prevFilters, searchQuery: query }))} />
          <Filters
            filters={filters}
            setFilters={setFilters}
            availableFilters={availableFilters}
            expandedFilters={expandedFilters}
            toggleFilterSection={toggleFilterSection}
          />               
        </div>
      )}
      <div className={isMobileView ? 'products-column mobile' : 'products-column'}>
        <span>{filteredProducts.length} Produtos</span>
        <div className="selected-filters">
          {!isAnyFilterApplied && (
            <p className="no-filters-message">Nenhum filtro aplicado. Exibindo todos os produtos.</p>
          )}
          {Object.keys(filters).map(key => (
            filters[key] && (
              Array.isArray(filters[key])
                ? filters[key].map(value => (
                    <div key={`${key}-${value}`} className="selected-filter">
                      <span>{getFilterName(key, value)}</span>
                      <button onClick={() => removeFilter(key, value)}>X</button>
                    </div>
                  ))
                : (
                  filters[key] && (
                    <div key={key} className="selected-filter">
                      <span>{getFilterName(key, filters[key])}</span>
                      <button onClick={() => removeFilter(key, filters[key])}>X</button>
                    </div>
                  )
                )
            )
          ))}
          {Object.values(filters).some(value => value && value.length > 0) && (
            <button className="clear-filters" onClick={clearAllFilters}>Limpar filtros</button>
          )}
        </div>
        <div className={isMobileView ? 'products-grid mobile' : 'products-grid'}>
          {displayedProducts.map(product => (
            <ProductCard key={product.ProdReference} product={product} />
          ))}
        </div>
        {isLoading && <p>Carregando...</p>}
        {!hasMore && <p>Sem mais produtos</p>}        
      </div>      
    </div>
  );  
};

export default CatalogPage;

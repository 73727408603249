import React, { useState, useRef } from 'react';
import './LoginRegisterModal.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAlert } from '../context/AlertContext';
import { useAuth } from '../context/AuthContext';

const LoginRegisterModal = ({ showModal, closeModal }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const { addAlert } = useAlert();
  const { login } = useAuth();

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    confirmEmail: '',
    tel: '',
    identifier: '',
    idType: 'cpf', // Adicionado para alternar entre CPF e CNPJ
    password: '',
    confirmPassword: '',
    recaptchaToken: '',
  });
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({ text: '', color: '', width: '0%' });
  const [captchaValue, setCaptchaValue] = useState(null);

  const recaptchaRef = useRef(null); // Referência para o reCAPTCHA

  const toggleRegistering = () => setIsRegistering(!isRegistering);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setFormData((prevData) => ({
      ...prevData,
      recaptchaToken: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const handleIdTypeChange = (e) => {
    setFormData({ ...formData, idType: e.target.value, identifier: '' });
  };

  const checkPasswordStrength = (password) => {
    let strength = { text: 'Fraca', color: 'red', width: '25%' };
    if (password.length >= 8) {
      strength = { text: 'Moderada', color: 'orange', width: '50%' };
    }
    if (password.length >= 12 && /[A-Z]/.test(password) && /[0-9]/.test(password)) {
      strength = { text: 'Forte', color: 'green', width: '100%' };
    }
    setPasswordStrength(strength);
  };

  const validateCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  };

  const validateCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;
    return true;
  };

  const validatePhone = (phone) => {
    return /^[0-9]{10,11}$/.test(phone);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const resetFormAndCaptcha = () => {
    setFormData({
      nome: '',
      email: '',
      confirmEmail: '',
      tel: '',
      identifier: '',
      idType: 'cpf',
      password: '',
      confirmPassword: '',
      recaptchaToken: '',
    });
    setCaptchaValue(null);
    recaptchaRef.current.reset(); // Resetar o reCAPTCHA
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      if (formData.email !== formData.confirmEmail) {
        addAlert('Emails não conferem!', 'error');
        recaptchaRef.current.reset();
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        addAlert('Senhas não conferem!', 'error');
        recaptchaRef.current.reset();
        return;
      }
      if (formData.idType === 'cpf' && !validateCPF(formData.identifier)) {
        addAlert('CPF inválido!', 'error');
        recaptchaRef.current.reset();
        return;
      }
      if (formData.idType === 'cnpj' && !validateCNPJ(formData.identifier)) {
        addAlert('CNPJ inválido!', 'error');
        recaptchaRef.current.reset();
        return;
      }
      if (!validatePhone(formData.tel)) {
        addAlert('Telefone inválido!', 'error');
        recaptchaRef.current.reset();
        return;
      }
      if (!validateEmail(formData.email)) {
        addAlert('E-mail inválido', 'error');
        recaptchaRef.current.reset();
        return;
      }
    }
    if (!captchaValue) {
      addAlert('Por favor, complete o captcha!', 'error');
      return;
    }

    setLoading(true);

    const url = isRegistering ? `${process.env.REACT_APP_API_URL}/user-register` : `${process.env.REACT_APP_API_URL}/user-login`;
    const method = 'POST';
    const body = isRegistering
      ? {
          nome: formData.nome,
          email: formData.email,
          tel: formData.tel,
          identifier: formData.identifier,
          idType: formData.idType,
          password: formData.password,
          recaptchaToken: formData.recaptchaToken,
        }
      : {
          email: formData.email,
          password: formData.password,
          recaptchaToken: formData.recaptchaToken,
        };

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('data: ', data);
        addAlert(isRegistering ? 'Usuário cadastrado com sucesso!' : 'Login realizado com sucesso!', 'success');
        login(data.user, data.token); // Login automático após registro
        resetFormAndCaptcha(); // Resetar o formulário e o reCAPTCHA
        closeModal();
      } else {
        const errorData = await response.json();
        addAlert(`Erro: ${errorData.message}`, 'error');
        recaptchaRef.current.reset(); // Resetar o reCAPTCHA em caso de erro
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      addAlert('Erro ao enviar dados.', 'error');
      recaptchaRef.current.reset(); // Resetar o reCAPTCHA em caso de erro
    }

    setLoading(false);
  };

  return (
    <div className={`modal ${showModal ? 'show' : ''}`} onClick={() => { closeModal(); resetFormAndCaptcha(); }}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={() => { closeModal(); resetFormAndCaptcha(); }}>&times;</span>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            {isRegistering ? (
              <>
                <h2>Registrar</h2>
                <input type="text" name="nome" placeholder="Nome" value={formData.nome} onChange={handleInputChange} required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                <input type="email" name="confirmEmail" placeholder="Confirmar Email" value={formData.confirmEmail} onChange={handleInputChange} required />
                <input type="tel" name="tel" placeholder="Telefone" value={formData.tel} onChange={handleInputChange} required />
                
                <div className="radio-group">
                  <label>
                    <input type="radio" name="idType" value="cpf" checked={formData.idType === 'cpf'} onChange={handleIdTypeChange} />
                    CPF
                  </label>
                  <label>
                    <input type="radio" name="idType" value="cnpj" checked={formData.idType === 'cnpj'} onChange={handleIdTypeChange} />
                    CNPJ
                  </label>
                </div>
                
                <input type="text" name="identifier" placeholder={formData.idType === 'cpf' ? "CPF" : "CNPJ"} value={formData.identifier} onChange={handleInputChange} required />
                <input type="password" name="password" placeholder="Senha" value={formData.password} onChange={handleInputChange} required />
                <input type="password" name="confirmPassword" placeholder="Confirmar Senha" value={formData.confirmPassword} onChange={handleInputChange} required />
                <div className="password-strength">
                  <div className="strength-bar">
                    <div style={{ width: passwordStrength.width, backgroundColor: passwordStrength.color }}></div>
                  </div>
                  <div className="strength-text">{passwordStrength.text}</div>
                </div>
                <div className="captcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_GOOGLE}
                    onChange={handleCaptchaChange}
                  />
                </div>
                <button type="submit" disabled={loading}>{loading ? 'Carregando...' : 'Registrar'}</button>
                <p>
                  Já tem uma conta? <span onClick={toggleRegistering}>Login aqui</span>
                </p>
              </>
            ) : (
              <>
                <h2>Entrar</h2>
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleInputChange} required />
                <input type="password" name="password" placeholder="Senha" value={formData.password} onChange={handleInputChange} required />
                <div className="captcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_GOOGLE}
                    onChange={handleCaptchaChange}
                  />
                </div>
                <button type="submit" disabled={loading}>{loading ? 'Carregando...' : 'Login'}</button>
                <p>
                  Não tem uma conta? <span onClick={toggleRegistering}>Registrar aqui</span>
                </p>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginRegisterModal;

// src/components/EmailSubscription.js
import React, { useState } from 'react';
import './EmailSubscription.css';
import { useAlert } from '../../context/AlertContext';


const EmailSubscription = () => {
  const [email, setEmail] = useState('');  
  const { addAlert } = useAlert(); 


  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();    

    if (!validateEmail(email)) {
        addAlert('Por favor, insira um e-mail válido.', 'error');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cadastrarrss`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        addAlert('Obrigado por se inscrever!', 'success');
        setEmail('');
      } else {
        addAlert('Erro ao cadastrar. Tente novamente mais tarde.', 'error');
      }
    } catch (error) {
        addAlert('Erro ao cadastrar. Tente novamente mais tarde.', 'error');
    }
  };

  return (
    <div className='contato-rss'>
      <span>Receba promoções exclusivas:</span>
      <form onSubmit={handleSubmit}>
        <input 
          type='text' 
          placeholder='Informe seu e-mail' 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type='submit'>Cadastrar</button>
      </form>      
    </div>
  );
};

export default EmailSubscription;

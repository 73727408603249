// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaUser, FaShoppingCart  } from 'react-icons/fa';
import './Header.css';
import LoginRegisterModal from './LoginRegisterModal';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import { FaWhatsapp } from 'react-icons/fa'; // Importando ícones FontAwesome
import EmailSubscription from './EmailSubscription/EmailSubscription';



const Header = () => {
  const { cart } = useCart();
  const totalItems = cart.length;
  const { authState, logout } = useAuth();
  const isAuthenticated = authState.isAuthenticated;
  const isAdmin = authState.user?.isAdmin;

  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef(null);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const whatsappNumber = process.env.REACT_APP_WHATSAPPNUMBER;    

  const handleAtendimento = () =>{
    const message = "Olá, tenho uma dúvida referente ao seus produtos.";    
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.open(whatsappLink, '_blank');
  }

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  console.log('admin ? ', authState.user?.isAdmin);
  console.log('user ? ', authState);


  useEffect(() => {
    if (isOpen) {
      setMenuHeight(menuRef.current.scrollHeight);
    } else {
      setMenuHeight(0);
    }
  }, [isOpen]);

  return (
    <header className="header">
      <div className='linha-um'>
        <div className='itens-linha-um'>
          <div className="logo">
            {/* <Link to="/">
              <img src={isOpen ? '/images/canetas-logo-caneta-grande.svg' : '/images/canetas-logo-palavra-azul.svg'}
                className={`logo-image ${isOpen ? 'active' : ''}`}
                alt="Logo"
              />
            </Link> */}
            <Link to="/">
              <img src='/images/canetas-logo-palavra-azul.svg'
                className={`logo-image`}
                alt="Logo"
              />
            </Link>
            
          </div>
          <div className='contato-header'>
              <span>Atendimento: <FaWhatsapp onClick={handleAtendimento}/> (11) 99367-7390</span>
          </div> 
          {/* <div className='contato-rss'>
            <span>Receba promoções exclusivas:</span>
            <input type='text' placeholder='Informe seu e-mail'></input>
            <button value='cadastrar'>Cadastrar</button>
          </div>           */}
          <EmailSubscription />
          <div className="login-modal-btn-area">
            {isAuthenticated ? (
              <div  onClick={toggleProfileMenu} className="profile-icon">
                <FaUser />
                <div className={`profile-menu ${profileMenuOpen ? 'open' : ''}`}>
                  <ul>
                    <Link to="/profile"><li>Perfil</li></Link>
                    <Link to="/userquotespage"><li>Meus Orçamentos</li></Link>
                    {isAdmin && <Link to="/admin/banners"><li>Gerenciar Banners</li></Link>}
                    {isAdmin && <Link to="/admin/adminquotespage"><li>Gerenciar Orçamentos</li></Link>}

                    <li onClick={logout}>Sair</li>
                  </ul>
                </div>
              </div>
            ) : (
              <button onClick={openModal}>Login</button>
            )}
          </div>
        </div>
      </div>
      <div className='linha-dois'>    
        <div className='left-header'>
          <div className='icon-menu-area'>
            <div className="menu-icon" onClick={toggleMenu}>
              {isOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <nav ref={menuRef} className="nav-menu" style={{ height: menuHeight }}>
            <ul>
              <li><Link to="/">Início</Link></li>
              <li className='liWidthCustom'><Link to="/catalog">Todos os produtos</Link></li>
            </ul>
          </nav>
        </div>    
        <div className='right-header'>          
          <div className='cart-area'>
            <Link to="/cart">
              <div><FaShoppingCart /></div>
              <span> ({totalItems}) </span>
            </Link>
          </div>
        </div>
      </div>

      <LoginRegisterModal showModal={showModal} closeModal={closeModal} />
    </header>
  );
};

export default Header;

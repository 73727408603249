import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  return (
    <div className={`cookie-consent ${isVisible ? 'visible' : ''}`}>
      <div className="cookie-message">
        Utilizamos cookies para melhorar sua experiência. Ao continuar navegando, você concorda com nossa política de cookies.
      </div>
      <div className="cookie-button">
        <button onClick={handleAccept}>Aceitar</button>
      </div>
    </div>
  );
};

export default CookieConsent;

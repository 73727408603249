import React, { createContext, useReducer, useContext, useEffect } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      // Verificar se o produto já está no carrinho
      const existingItemIndex = state.findIndex(item => item.ProdReference === action.product.ProdReference);
      if (existingItemIndex !== -1) {
        // Atualizar a quantidade do produto existente
        const updatedCart = [...state];
        updatedCart[existingItemIndex].quantity += action.quantity;
        return updatedCart;
      }
      // Adicionar novo produto com quantidade e cor padrão (primeira cor disponível)
      return [...state, { ...action.product, quantity: action.quantity, selectedColor: action.product.Colors?.split(', ')[0] || '' }];
      
    case 'REMOVE_FROM_CART':
      return state.filter(item => item.ProdReference !== action.product.ProdReference);
      
    case 'UPDATE_QUANTITY':
      return state.map(item =>
        item.ProdReference === action.product.ProdReference
          ? { ...item, quantity: action.quantity }
          : item
      );
      
    case 'UPDATE_COLOR':
      return state.map(item =>
        item.ProdReference === action.product.ProdReference
          ? { ...item, selectedColor: action.color }
          : item
      );
      
    case 'CLEAR_CART':
      return [];
      
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  // Carregar o carrinho do localStorage, se existir
  const [cart, dispatch] = useReducer(cartReducer, [], () => {
    const localData = localStorage.getItem('cart');
    return localData ? JSON.parse(localData) : [];
  });

  // Salvar o carrinho no localStorage sempre que ele for atualizado
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

export default CartContext;

// src/components/BannerCarousel.js
import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './BannerCarousel.css';
import { useLoading } from '../context/LoadingContext';


const CACHE_DURATION = 60 * 60 * 1000; // 1 hora em milissegundos

const BannerCarousel = () => {
  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setLoading } = useLoading();
  

  
  const handleBanner = (url) => {        
    window.open(url, '_blank');
  };
  useEffect(() => {
         
    const fetchBanners = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/banners/active`, {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        setBanners(data);  
        setIsLoading(false);
        // Armazena os dados no localStorage com um timestamp
        //localStorage.setItem('banners', JSON.stringify(data));
        //localStorage.setItem('bannersLastFetch', Date.now().toString());
        setLoading(false);
        
        
      } catch (error) {
        console.error('Erro ao carregar banners:', error);
        setLoading(false);
      }
    };

    const cachedBanners = localStorage.getItem('banners');
    const bannersLastFetch = localStorage.getItem('bannersLastFetch');
    const isCacheValid = bannersLastFetch && (Date.now() - parseInt(bannersLastFetch)) < CACHE_DURATION;

    
    fetchBanners();    

  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Carousel
      showThumbs={false}
      infiniteLoop
      autoPlay
      interval={2500}
      showStatus={false}
      showIndicators={true}
      swipeable
      stopOnHover
      dynamicHeight
    >
      {banners.map((banner, index) => (
        <div key={index} className="banner-container">
          <img src={banner.image} alt={banner.title} className="banner-image"/>
          {banner.link.length > 0 ? 
          (<div className="legend">
            <h2>{banner.title}</h2>
            <p>{banner.description}</p>
            <a href={banner.link} target="_blank" rel="noopener noreferrer" className="banner-link">Clique aqui e saiba mais</a>
          </div>) : (<div></div>)}          
        </div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;

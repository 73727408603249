import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import WhatsAppTemplate from '../components/WhatsAppTemplate';
import './CheckoutPage.css';
import { useAlert } from '../context/AlertContext';
import { FaClipboardList } from "react-icons/fa";

const CheckoutPage = () => {
  const removePrefix = (str) => {
    const index = str.indexOf('.');
    if (index === -1) return str.trim();
    return str.substring(index + 1).trim();
  };

  const { cart, dispatch } = useCart();
  const navigate = useNavigate();
  const { addAlert } = useAlert(); 
  const { authState } = useAuth(); 

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    imagem: null,
    descricao: ''
  });

  const [errorFields, setErrorFields] = useState({
    nome: false,
    email: false,
    telefone: false,
  });

  const [whatsAppUrl, setWhatsAppUrl] = useState('');

  useEffect(() => {
    if (authState.isAuthenticated) {
      setFormData({
        ...formData,
        nome: authState.user.nome || '',
        email: authState.user.email || '',
        telefone: authState.user.tel || ''
      });
    }
  }, [authState.isAuthenticated]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value
    }));
    setErrorFields((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Remove o erro quando o usuário começa a digitar
    }));
  };

  const validateForm = () => {
    const { nome, email, telefone } = formData;
    const errors = {
      nome: !nome,
      email: !email,
      telefone: !telefone,
    };

    setErrorFields(errors);

    const hasErrors = Object.values(errors).some((field) => field);

    if (hasErrors) {
      addAlert('Por favor, preencha todos os campos obrigatórios!', 'error');
      return false;
    }

    return true;
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const formDataToSubmit = {
      nome: formData.nome,
      email: formData.email,
      telefone: formData.telefone,
      descricao: formData.descricao,
      cart: cart.map(item => ({
        ...item,
        quantity: item.quantity,
        selectedColor: item.selectedColor // Adicionando a cor selecionada
      })),
      imagem: null,
    };

    if (formData.imagem) {
      const reader = new FileReader();
      reader.readAsDataURL(formData.imagem);
      reader.onloadend = async () => {
        formDataToSubmit.imagem = reader.result;

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataToSubmit)
          });

          if (response.ok) {
            const data = await response.json();
            addAlert('Orçamento enviado com sucesso!', 'success');
            setWhatsAppUrl(data.whatsAppUrl);
            dispatch({ type: 'CLEAR_CART' });
            navigate('/catalog');
          } else {
            const errorData = await response.json();
            console.error('Erro ao enviar orçamento:', errorData);
            addAlert('Erro ao enviar orçamento.', 'error');
          }
        } catch (error) {
          console.error('Erro ao enviar orçamento:', error);
          addAlert('Erro ao enviar orçamento.', 'error');
        }
      };
    } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentos`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formDataToSubmit)
        });

        if (response.ok) {
          const data = await response.json();
          addAlert('Orçamento enviado com sucesso!', 'success');
          setWhatsAppUrl(data.whatsAppUrl);
          dispatch({ type: 'CLEAR_CART' });
          navigate('/catalog');
        } else {
          const errorData = await response.json();
          console.error('Erro ao enviar orçamento:', errorData);
          addAlert('Erro ao enviar orçamento.', 'error');
        }
      } catch (error) {
        console.error('Erro ao enviar orçamento:', error);
        addAlert('Erro ao enviar orçamento.', 'error');
      }
    }
  };

  const handleNoWhatsAppSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/orcamentos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          nome: formData.nome,
          email: formData.email,
          telefone: formData.telefone,
          descricao: formData.descricao,
          cart: cart.map(item => ({
            ...item,
            quantity: item.quantity,
            selectedColor: item.selectedColor // Adicionando a cor selecionada
          })),
        })
      });

      if (response.ok) {
        addAlert('Orçamento salvo com sucesso!', 'success');
        dispatch({ type: 'CLEAR_CART' });
        navigate('/catalog');
      } else {
        const errorData = await response.json();
        console.error('Erro ao salvar orçamento:', errorData);
        addAlert('Erro ao salvar orçamento.', 'error');
      }
    } catch (error) {
      console.error('Erro ao salvar orçamento:', error);
      addAlert('Erro ao salvar orçamento.', 'error');
    }
  };

  return (
    <div className="checkout-page">
      <div className='titleAreaWithIcons'><h1>Finalizar Pedido</h1></div>
      <form className="checkout-form" onSubmit={handleSubmit}>
        <label>
          Nome:
          <input type="text" name="nome" value={formData.nome} onChange={handleInputChange} className={errorFields.nome ? 'input-error' : ''} required />
        </label>
        <label>
          E-mail:
          <input type="email" name="email" value={formData.email} onChange={handleInputChange} className={errorFields.email ? 'input-error' : ''}required />
        </label>
        <label>
          Celular:
          <input type="tel" name="telefone" value={formData.telefone} onChange={handleInputChange} className={errorFields.telefone ? 'input-error' : ''} required />
        </label>
        <label>
          Enviar Imagem:
          <input type="file" name="imagem" onChange={handleInputChange} />
        </label>
        <label>
          Descrição Adicional:
          <textarea name="descricao" value={formData.descricao} onChange={handleInputChange}></textarea>
        </label>
      </form>
      <WhatsAppTemplate 
        nome={formData.nome}
        email={formData.email}
        telefone={formData.telefone}
        descricao={formData.descricao}
        cart={cart}
        validateForm={validateForm}
      />
      {whatsAppUrl && (
        <div className="whatsapp-link">
          <a href={whatsAppUrl} target="_blank" rel="noopener noreferrer">
            Enviar Orçamento via WhatsApp
          </a>
        </div>
      )}
      <div className="no-whatsapp">
        <p>Não possui WhatsApp?</p>
        <button onClick={handleNoWhatsAppSubmit}>Enviar orçamento por aqui</button>
      </div>
      <div className="cart-summary">
        <div className='titleAreaWithIcons'><FaClipboardList /><h2>Resumo do Pedido</h2><FaClipboardList className='invert-icon'/></div>
        {cart.map((item) => (
          <div key={item.ProdReference} className="cart-summary-item">
            <img src={`https://www.spotgifts.com.br/fotos/produtos/${item.MainImage}`} alt={item.ProdName} />
            <div className="cart-summary-details">
              <h3>{removePrefix(item.Name)}</h3>
              <p>{item.ShortDescription}</p>
              <p>Quantidade: {item.quantity}</p>
              <p>Cor Selecionada: {item.selectedColor || 'Não especificada'}</p>
            </div>
          </div>
        ))}
      </div>      
    </div>
  );
};

export default CheckoutPage;
